// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-contact-index-mdx": () => import("./../src/pages/contact/index.mdx" /* webpackChunkName: "component---src-pages-contact-index-mdx" */),
  "component---src-pages-contact-success-mdx": () => import("./../src/pages/contact/success.mdx" /* webpackChunkName: "component---src-pages-contact-success-mdx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

